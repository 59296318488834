import { configureStore } from '@reduxjs/toolkit';
import orderingReducer from './orderingSlice';

export const createStore = () => {
  return configureStore({
    reducer: {
      ordering: orderingReducer
    }
  });
}
