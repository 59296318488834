import React from "react";
import Ordering from "../Ordering";
import { createStore } from "../store/store";
import { Provider } from "react-redux";

export default function OrderingWrapper(props) {
  return (
    <React.StrictMode>
      <Provider store={createStore()}>
        <Ordering {...props} />
      </Provider>
    </React.StrictMode>
  );
}
