import { StyleItemButton } from "./StyleItemButton";
import { StyleItemListItem } from "./StyleItemListItem";
import { StyleItemThumb } from "./StyleItemThumb";
import { InvalidSVG } from "./InvalidSVG";
import { useSelector, useDispatch } from "react-redux";
import {
  selectSelectedStyleItem,
  updateSelectedStyleItem,
} from "../store/orderingSlice";
import classNames from "classnames";

export function StyleItemList({ style, styleImages = false }) {
  const dispatch = useDispatch();

  const selected_style_item = useSelector(
    selectSelectedStyleItem(style.style_ref)
  );

  const label_class_name = classNames([
    "section_label",
    "style_ordering-label",
    "pb-2",
  ]);

  const ul_class_name = classNames(["style_ordering-style_row", "pb-3", "cf"]);

  const button_class_name = classNames({
    btn: true,
    "style_ordering-button": !styleImages,
    "style_ordering-image_wrap": styleImages,
  });

  const onStyleItemClick = (clicked_style_item_ref) => {
    if (selected_style_item?.style_item_ref === clicked_style_item_ref) {
      dispatch(
        updateSelectedStyleItem({
          style_ref: style.style_ref,
          style_item_ref: null,
        })
      );
    } else {
      dispatch(
        updateSelectedStyleItem({
          style_ref: style.style_ref,
          style_item_ref: clicked_style_item_ref,
        })
      );
    }
  };

  return (
    <>
      <label className={label_class_name}>
        {style.style_name}:
        {selected_style_item && (
          <>
            <span className="style_ordering-label-selected">
              {selected_style_item.style_item_name}
            </span>
            {styleImages && (
              <span className="style_ordering-label-model colorstocknum">
                {selected_style_item.style_item_model}
              </span>
            )}
          </>
        )}
      </label>
      <ul className={ul_class_name}>
        {style.style_items.map((si) => {
          return (
            <StyleItemListItem
              styleItem={si}
              styleImages={styleImages}
              key={si.style_item_ref}
            >
              <StyleItemButton
                onClick={() => onStyleItemClick(si.style_item_ref)}
                className={button_class_name}
                styleItem={si}
                styleImages={styleImages}
              >
                {styleImages ? (
                  <StyleItemThumb styleItem={si} />
                ) : (
                  si.style_item_name
                )}
                <InvalidSVG show={!si.valid} />
              </StyleItemButton>
            </StyleItemListItem>
          );
        })}
      </ul>
    </>
  );
}
