import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from 'react';
import { BulkOrderingMainImage } from "./BulkOrderingMainImage";
import classNames from "classnames";

import {
  selectBulkOrderDrawerSelectedStyleItem,
  selectBulkOrderDrawerSelectedProducts,
  bulkOrderAddToCart,
} from "../store/orderingSlice";
import React from "react";
import { BulkOrderTableRow } from "./BulkOrderTableRow";
import { isProductOrderable } from "../utilities/product_utilities";

export function BulkOrderingDrawerForm() {
  const style_item = useSelector(selectBulkOrderDrawerSelectedStyleItem);
  const products = useSelector(selectBulkOrderDrawerSelectedProducts);
  const dispatch = useDispatch();

  const mapProductsToInitialState = (products) => {
    let initial_qty = 1;
    products.forEach(p => {
      if(p.styles.length > 1)
      {
        initial_qty = 0;
      }
    });
    return products.map(p => {
      let is_orderable = isProductOrderable(p);
      return {
        quantity: is_orderable === false ? 0 : initial_qty,
        product_data: p
      }
    })
  };

  const [orderingState,setOrderingState] = useState(mapProductsToInitialState(products));

  useEffect(
    () => {
      setOrderingState(mapProductsToInitialState(products));
    },
    [products]
  );

  let form_is_submittable = false;
  let order_total = 0;
  let has_size = false;

  orderingState.forEach(os => {
    if(os.quantity !== "" && os.quantity > 0)
    {
      form_is_submittable = true;
      order_total += os.product_data.price * os.quantity;      
    }
    if(os.product_data.styles.length > 1)
    {
      has_size = true;
    }
  });
  let default_quantity = has_size ? 0 : 1;

  const button_text = "Add to Cart" + (order_total > 0 ? " - $" + order_total.toFixed(2) : "");

  const button_class_name = classNames({
    "bulk-drawer__add-to-cart-button" : true,
    "btn": true,
    "button-cart": true,
    "btn-block":true,
    "disabled":!form_is_submittable
  });

  const table_class_name = classNames({
    "bulk-drawer__table": true,
    "bulk-drawer__table--with-size": has_size,
    "bulk-drawer__table--without-size": !has_size
  });

  const incrementQuantity = (code_to_update) => {
    const updatedOrderingState = orderingState.map(os => {
      if(os.product_data.code === code_to_update)
      {
        return {
          ...os,
          quantity: os.quantity === "" ? default_quantity : os.quantity + 1
        }
      }
      else 
      {
        return os
      }
    });
    setOrderingState(updatedOrderingState);
  }

  const decrementQuantity = (code_to_update) => {
    const updatedOrderingState = orderingState.map(os => {
      if(os.product_data.code === code_to_update)
      {
        return {
          ...os,
          quantity: os.quantity === "" ? default_quantity : Math.max(os.quantity - 1, default_quantity)
        }
      }
      else 
      {
        return os
      }
    });
    setOrderingState(updatedOrderingState);
  }


  const updateQuantity = (code_to_update,new_quantity) => {
    let parsed_quantity = parseInt(new_quantity);
    
    if(isNaN(parsed_quantity))
    {
      parsed_quantity = "";
    }
    
    // map state array:
    // Pass through non-matching codes.
    // On match, spread object to copy with updated quantity
    const updatedOrderingState = orderingState.map(os => {
      if(os.product_data.code === code_to_update)
      {
        return {
          ...os,
          quantity: parsed_quantity
        }
      }
      else 
      {
        return os
      }
    });
    setOrderingState(updatedOrderingState);
  }
  
  const addToCart = () => {
    const ordered_products = orderingState.filter(os => os.quantity !== "" && os.quantity > 0);
    const ordered_event_data = ordered_products.map(op => {
      return {
        "code": op.product_data.code,
        "quantity": op.quantity
      }
    });

    if(ordered_event_data.length > 0)
    {
      dispatch(bulkOrderAddToCart(ordered_event_data));
    }
    setOrderingState(mapProductsToInitialState(products));

  }

  return (
    <div>
      <div className="bulk-drawer__info-section">
        <BulkOrderingMainImage
          styleItem={style_item}
          className="bulk-drawer__image"
        />
        <div className="bulk-drawer__name">{style_item.style_item_name}</div>
        <div className="bulk-drawer__model">{style_item.style_item_model}</div>
      </div>
      
      <div className="bulk-drawer__table-wrap">
        <table className={table_class_name}>
          <thead>
            <tr>
              {has_size && <th>Size</th>}
              <th>Price</th>
              <th>Qty</th>
            </tr>
          </thead>
          <tbody>
            {orderingState.map(os => 
              <BulkOrderTableRow
                key={os.product_data.code}
                product={os.product_data}
                updateQuantity={updateQuantity}
                decrementQuantity={decrementQuantity}
                incrementQuantity={incrementQuantity}
                quantity={os.quantity}
                hasSize={has_size}
              />
            )}
          </tbody>
        </table>
      </div>

      <div className="bulk-drawer__add-to-cart-wrap">
        <button
          className={button_class_name}
          type="button"
          disabled={!form_is_submittable}
          onClick={(e) => {addToCart()}}
        >{button_text}</button>
      </div>

    </div>
  );
}