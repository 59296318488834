import { ResponsiveProductImage } from "./ResponsiveProductImage";
import { useSelector } from "react-redux";
import {
  selectOwnerCode,
  selectResizeURL
} from "../store/orderingSlice";

export function BulkOrderingThumb({ styleItem, className = "thumbnail"  }) {
  const owner_code = useSelector(selectOwnerCode);
  const resize_url = useSelector(selectResizeURL);
  const size_breakpoints = {
    0: 180,
    480: 210,
    576: 250,
    768: 210,
    992: 230,
    1200: 210    
  };
  const srcset_widths = [
    180,
    210, 
    230, 
    250, 
    360, 
    420, 
    460, 
    500
  ];
  const default_width = 210;

  return (
    <ResponsiveProductImage
      resizeURL={resize_url}
      path={owner_code + "-" + styleItem.style_item_code + "-1.jpg"}
      srcsetWidths={srcset_widths}
      sizeBreakpoints={size_breakpoints}
      defaultWidth={default_width}
      alt={styleItem.style_item_name}
      className={className} />
  );
}
