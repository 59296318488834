export function SortSelect({sortBy,onChange,sortableByPrice = false}) {
  return (
    <select
      className="color-drawer__sort-select"
      onChange={onChange}
      value={sortBy}
    >
      <option value="relevance">Relevance</option>
      <option value="popular">Popularity</option>
      {sortableByPrice && <>
          <option value="low_price">Low Price</option>
          <option value="high_price">High Price</option>      
        </>
      }
      <option value="a_to_z">A to Z</option>
      <option value="z_to_a">Z to A</option>
    </select>
  );
}
