export function getProductArray(ordering_data) {
  const product_array = ordering_data.products;
  
  //add low_stock, true if availalble text is only digits
  const low_stock_pattern = /^\d+$/;  
  product_array.forEach(p => {
    p.low_stock = low_stock_pattern.test(p.available_text);
  })
  return product_array;
}

export function getProductStyles(ordering_data) {
  const styles = [];
  ordering_data.products.forEach((p) => {
    p.styles.forEach((style, style_index) => {
      if (typeof styles[style_index] === "undefined") {
        styles[style_index] = {
          style_abbrev: style.style_abbrev,
          style_name: style.style_name,
          style_ref: style.style_ref,
          selected_style_item_ref: null,
          style_items: [],
        };
      }

      let style_item_match = styles[style_index].style_items.find(
        (si) => si.style_item_ref === style.style_item_ref
      );

      if (typeof style_item_match === "undefined") {
        const style_item_object = {
          style_item_name: style.style_item_name,
          style_item_code: style.style_item_code,
          style_item_ref: style.style_item_ref,
          style_item_model: style.style_item_model,
          selected: false,
          valid: false,
          min_price: null,
          max_price: null,
          best_seller: false,
          new_item: false,
          closeout_item: false,
          sale_item: false,
          reduced_item: true //default true, set to false if any style item product is not reduced
        };

        //no match, set newly created object to match for further processing
        style_item_match = style_item_object;
        styles[style_index].style_items.push(style_item_match);
      }

      if(style_item_match.min_price === null || style_item_match.min_price > p.price)
      {
        style_item_match.min_price = p.price;
      }

      if(style_item_match.max_price === null || style_item_match.max_price < p.price)
      {
        style_item_match.max_price = p.price;
      }
      
      //apply product flags to style item if true, if any style match is true the flag is true
      const product_flags = [
        'best_seller',
        'new_item',
        'closeout_item',
        'sale_item',
      ];

      product_flags.forEach(f => {
        style_item_match[f] = style_item_match[f] || p[f];
      });

      //apply reduced flag, this is only true if all of the style matches are reduced.
      style_item_match.reduced_item = style_item_match.reduced_item && p.reduced_item;

    });
  });

  //pre-select styles that have one style item
  styles.forEach(s => {
    if(s.style_items.length === 1)
    {
      s.selected_style_item_ref = s.style_items[0].style_item_ref;
      s.style_items[0].selected = true;
    }
  });

  return styles;
}

export function getProductArrayPriceRange(product_array) {
  let max_price = null;
  let min_price = null;

  let max_list = null;
  let min_list = null;

  let max_savings = null;
  let min_savings = null;

  let max_percent_savings = null;
  let min_percent_savings = null;

  product_array.forEach((p) => {
    if (max_price === null || p.price > max_price) {
      max_price = p.price;
    }
    if (min_price === null || p.price < min_price) {
      min_price = p.price;
    }
    if(typeof p.list_price !== 'undefined')
    {
      if (max_list === null || p.list_price > max_list) {
        max_list = p.list_price;
      }
      if (min_list === null || p.list_price < min_list) {
        min_list = p.list_price;
      }

      let savings = p.list_price - p.price;
      let percent_savings = (100 * savings) / p.list_price;
      
      //round savings to two decimal places and set min and max if applicable
      savings = parseFloat(savings.toFixed(2));
      if (max_savings === null || savings > max_savings) {
        max_savings = savings;
      }
      if (min_savings === null || savings < min_savings) {
        min_savings = savings;
      }

      //round percent savings to whole number
      percent_savings = parseInt(percent_savings.toFixed());
      if (max_percent_savings === null || percent_savings > max_percent_savings) {
        max_percent_savings = percent_savings;
      }
      if (min_percent_savings === null || percent_savings < min_percent_savings) {
        min_percent_savings = percent_savings;
      }
    }
  });
  return {
    min_price: min_price,
    max_price: max_price,
    min_list_price: min_list,
    max_list_price: max_list,
    min_savings: min_savings,
    max_savings: max_savings,
    min_percent_savings: min_percent_savings,
    max_percent_savings: max_percent_savings
  };
}
