import { useEffect } from "react";

export function useListener(target, event_name, listener) {
    useEffect(
      () => {
        target.addEventListener(event_name, listener);
        const cleanup = () => {
          target.removeEventListener(event_name, listener);
        }
        return cleanup;
      },
      // eslint-disable-next-line
      [] // empty dependencies array so effect function only runs on mount and unmount
    );
  }