export function FilterInput({filterText,onChange}) {
  return (
    <div className="input-group">
      <input
        className="form-control"
        placeholder="Search"
        aria-label="Search"
        value={filterText} 
        onChange={onChange}
        type="search"
      />
    </div>
  );
}


