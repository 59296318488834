import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState, Fragment } from "react";
import {
  selectAllProducts,
  selectOwnerName,
  selectSpecsDrawerSelectedProductCode,
  selectSpecsDrawerSelectedProduct,
  selectSpecsURL,
  setSpecsDrawerSelectedProductCode,
} from "../store/orderingSlice";
import { SpecsOrdering } from "./SpecsOrdering";

function SpecsLoadingMessage() {
  return (
    <div className="text-center">
      <div className="spinner-border m-5" role="status"></div>
      <div>Loading...</div>
    </div>
  );
}

function SpecsLoadingErrorMessage() {
  return (
    <div class="alert alert-danger is-invalid">
      <span class="icon icon-warning"></span>Error fetching model
      specifications.
    </div>
  );
}

function SpecsFetchedMarkup({ loading, error, markup }) {
  if (loading) {
    return (
      <div className="specs-panel__description-wrapper">
        <SpecsLoadingMessage />
      </div>
    );
  }

  if (error) {
    return (
      <div className="specs-panel__description-wrapper">
        <SpecsLoadingErrorMessage />
      </div>
    );
  }

  return (
    <div
      className="specs-panel__description-wrapper"
      dangerouslySetInnerHTML={{ __html: markup }}
    ></div>
  );
}

export function SpecsOrderingDrawerForm() {
  const dispatch = useDispatch();
  const specs_base_url = useSelector(selectSpecsURL);
  const owner_name = useSelector(selectOwnerName);
  const products = useSelector(selectAllProducts);
  const selected_product = useSelector(selectSpecsDrawerSelectedProduct);
  const selected_code = useSelector(selectSpecsDrawerSelectedProductCode);
  const selected_value = selected_code ? selected_code : "";

  const [markup, setMarkup] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingError, setLoadingError] = useState(false);

  // Effect to fetch specs markup before displaying ordering and specs
  useEffect(() => {
    let ignore = false;
    if (selected_code && specs_base_url) {
      const url = `${specs_base_url}?pcode=${selected_value}`;
      setMarkup(null);
      setLoading(true);
      fetch(url)
        .then((resp) => resp.text())
        .then((html) => {
          if (!ignore) {
            setMarkup(html);
            setLoading(false);
            setLoadingError(false);
          }
        })
        .catch((error) => {
          if (!ignore) {
            console.error("There was a problem fetching specs:", error);
            setMarkup(null);
            setLoadingError(true);
            setLoading(false);
          }
        });
    }

    const cleanup = () => {
      //ignore responses after cleanup
      ignore = true;
    };

    return cleanup;
  }, [specs_base_url, selected_code]);

  const changeHandler = (e) => {
    const value = e.target.value;
    const selected_pcode = value ? value : null;
    dispatch(setSpecsDrawerSelectedProductCode(selected_pcode));
  };

  return (
    <div className="specs-panel__content-wrapper">
      <div className="specs-panel__content">
        <div className="specs-panel__product-name">
          <h2>{owner_name}</h2>
        </div>
        <div className="specs-panel__model-wrapper">
          <label
            className="specs-panel__select-label"
            htmlFor="specs-panel__model-select"
          >
            Select a Model:
          </label>
          <select
            id="specs-panel__model-select"
            className="specs-panel__models"
            name="specs_select"
            value={selected_value}
            onChange={changeHandler}
          >
            <option value="">Select a Model</option>
            {products.map((p) => (
              <option key={p.code} value={p.code}>
                {p.name}
              </option>
            ))}
          </select>
        </div>
        {selected_product && (
          <SpecsFetchedMarkup
            loading={loading}
            error={loadingError}
            markup={markup}
          />
        )}
      </div>
      {selected_product && !loading && (
        <SpecsOrdering product={selected_product} />
      )}
    </div>
  );
}
