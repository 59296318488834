import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect, Fragment } from "react";
import { createPortal } from "react-dom";
import {
  initialize,
  selectStyles,
  selectStyleImages,
  selectMaxStyleListColors,
  selectColorDrawerIsOpen,
  dismissColorDrawer,
  openColorDrawer,
  selectBulkOrderDrawerIsOpen,
  dismissBulkDrawer,
  selectBulkOrderDrawerSelectedStyleItem,
  selectAllSelectionsMade,
  selectCurrentMatchingProducts,
  selectShowStockMessage,
  selectSpecsURL,
  selectSpecsDrawerIsOpen,
  dismissSpecsOrdering,
  openSpecsOrdering,
  openSpecsOrderingForProduct
} from "./store/orderingSlice";
import { StyleItemList } from "./components/StyleItemList";
import { ColorStyleSelect } from "./components/ColorStyleSelect";
import { BulkOrdering } from "./components/BulkOrdering";
import { OffCanvasDrawerModal } from "./components/OffCanvasDrawerModal";
import { BulkOrderingDrawerForm } from "./components/BulkOrderingDrawerForm";
import { SelectedStockMessage } from "./components/SelectedStockMessage";
import { SpecsOrderingDrawerForm } from "./components/SpecsOrderingDrawerForm";
import { useListener } from "./utilities/custom_hooks";



export default function Ordering({ appContainer, productJSON, bulkOrderingContainer, bulkOrderingContainerId, stockMessageContainer, showStockMessage = true }) {
  const dispatch = useDispatch();
  const [initialized, setIntialized] = useState(false);
  const styles = useSelector(selectStyles);
  const style_images = useSelector(selectStyleImages);
  const max_style_list_colors  = useSelector(selectMaxStyleListColors);
  const too_many_color_styles = style_images && styles[0].style_items.length > max_style_list_colors;
  const color_drawer_is_open = useSelector(selectColorDrawerIsOpen);  
  const bulk_drawer_is_open = useSelector(selectBulkOrderDrawerIsOpen);
  const bulk_drawer_selected_style_item = useSelector(selectBulkOrderDrawerSelectedStyleItem);
  const use_bulk_ordering = bulkOrderingContainer && style_images;
  const all_selections_made = useSelector(selectAllSelectionsMade);
  const current_matches = useSelector(selectCurrentMatchingProducts);
  const show_stock_message = useSelector(selectShowStockMessage);  
  const specs_url = useSelector(selectSpecsURL);
  const specs_drawer_is_open = useSelector(selectSpecsDrawerIsOpen)

  const openSpecsDrawerListener = (e) => {
    dispatch(openSpecsOrderingForProduct(e.detail?.code));
  }

  useEffect(
    () => {
      if (!initialized) {
        setIntialized(true);
        dispatch(
          initialize({
            productJSON: productJSON,
            appContainer: appContainer,
            showStockMessage: showStockMessage
          })
        );
      }
    },
    // eslint-disable-next-line
    [] // empty dependencies array so effect function only runs on mount and unmount
  );

  useListener(appContainer, 'ordering:triggerOpenSpecsDrawer', openSpecsDrawerListener);

  if(!initialized)
  {
    return <></>;
  }

  return (
    <>
      <div className="style_ordering-box">
        {styles.map((style, index) => (
          <Fragment key={style.style_ref}>
           {too_many_color_styles && index === 0
            ? <ColorStyleSelect                
                style={style}
                styleImages={style_images && index === 0}
                onClick={() => dispatch(openColorDrawer())}
                dismissDrawer={() => dispatch(dismissColorDrawer())}
                isOpen={color_drawer_is_open}
              />            
            : <StyleItemList
                style={style}
                styleImages={style_images && index === 0}
              />
            }
            {use_bulk_ordering && index === 0 &&
              <div className="browse_color_link-wrap pb-4">
                <a className="browse_color_link smooth_scroll" href={`#${bulkOrderingContainerId}`}>Browse All Colors</a>
              </div>
            }
          </Fragment>
        ))}
      </div>

      {specs_url && 
        <a
          className="product-specs-link pb-4 d-block valid-link"
          aria-label="Product Specs"
          title="Product Specs"
          href="#"
          onClick={e => {
            e.preventDefault();
            dispatch(openSpecsOrdering())
          }}
        >View Specifications</a>
      }

      {specs_url && 
        <OffCanvasDrawerModal
          dismissDrawer={() => dispatch(dismissSpecsOrdering())}
          isOpen={specs_drawer_is_open}
        >
          <div className="specs-panel">
            <div className="sticky-top">
              <div className="dynamic_cart-header">
                <h3>Specifications</h3>
                <button
                  type="button"
                  className="icon icon-x dynamic_cart-header-close"
                  title="Close Cart"
                  onClick={() => dispatch(dismissSpecsOrdering())}
                >
                  Close
                </button>
              </div>
            </div>
            <SpecsOrderingDrawerForm />
          </div>
        </OffCanvasDrawerModal>
      }
      


      {show_stock_message && all_selections_made && 
        <>
          {stockMessageContainer ?
            createPortal(
              <SelectedStockMessage
                matches={current_matches}   
              />,
              stockMessageContainer
            )
            :
            (<SelectedStockMessage
              matches={current_matches}   
            />)
          }
        </>
      }

      {use_bulk_ordering && (
        <BulkOrdering container={bulkOrderingContainer} />
      )}
      {use_bulk_ordering &&
        <OffCanvasDrawerModal
          dismissDrawer={() => dispatch(dismissBulkDrawer())}
          isOpen={bulk_drawer_is_open}
        >
          {bulk_drawer_selected_style_item &&
            <div className="">
              <div className="sticky-top">
                <div className="dynamic_cart-header">
                  <h3>Select Size</h3>
                  <button
                    type="button"
                    className="icon icon-x dynamic_cart-header-close"
                    title="Close Modal"
                    onClick={() => dispatch(dismissBulkDrawer())}
                  >
                    Close
                  </button>
                </div>
              </div>
              <BulkOrderingDrawerForm />
            </div>
          }
        </OffCanvasDrawerModal>
      }
    </>
  );
}
