import { useEffect, useState, useRef } from "react";
import { createPortal } from "react-dom";
import classNames from "classnames";

export function OffCanvasDrawerModal({ isOpen, dismissDrawer, children }) {

  const [initialScroll, setInitialScroll] = useState(null);
  const drawer_ref = useRef();
  const [originalActiveElement,setOriginalAcitveElement] = useState(null);

  const focusable_selectors = [
    'a[href]:not([disabled])',
    'button:not([disabled])',
    'textarea:not([disabled])',
    'input[type="text"]:not([disabled])',
    'input[type="radio"]:not([disabled])',
    'input[type="checkbox"]:not([disabled])',
    'select:not([disabled])'
  ];

  const focusable_selectors_string = focusable_selectors.join(", ");

  const trapFocusKeydownListener = (e) => {
    const KEYCODE_TAB = 9;
    const KEYCODE_ESC = 27;
    const tabPressed = (e.key === 'Tab' || e.keyCode === KEYCODE_TAB);
    const escPressed = (e.key === 'Escape' || e.keyCode === KEYCODE_ESC);
    const shiftPressed = e.shiftKey;
    const target = e.target;

    //Escape Key
    if(escPressed)
    {
      dismissDrawer();
      return;
    }

    //Not Tab
    if(!tabPressed)
    {
      return;
    }
    
    //Select the focusable elements
    const focusables = drawer_ref.current.querySelectorAll(focusable_selectors_string);
    const first_focusable = focusables[0];
    const last_focusable = focusables[focusables.length -1];

    //Shift-Tab
    if(shiftPressed)
    {
      if(target === first_focusable)
      {
        last_focusable.focus();
        last_focusable.scrollIntoView();
        e.preventDefault();
      }
      return;
    }

    //Tab
    if(target === last_focusable)
    {
      first_focusable.focus();
      first_focusable.scrollIntoView();
      e.preventDefault();
    }
  }

  useEffect(() => {
    if (isOpen) {
      const current_scroll = window.scrollY;
      setInitialScroll(current_scroll);
      document.body.classList.add("is-fixed", "sticky-ignore-next-scroll");
      document.body.style.setProperty("top", "-" + current_scroll + "px");
    } else {
      if(initialScroll !== null)
      {
        document.body.style.removeProperty("top");
        document.body.classList.remove("is-fixed");
        document.body.classList.add("sticky-ignore-next-scroll");
        window.scrollTo(0, initialScroll);
      }
    }
  }, [isOpen]);

  //effect to bind/unbind trap focus logic
  useEffect(
    () => {     
      if(isOpen) {
        setOriginalAcitveElement(document.activeElement);
        const initial_focus = drawer_ref.current.querySelector(focusable_selectors_string);
        if(initial_focus)
        {
          setTimeout(() => {initial_focus.focus()},100);
        }
        drawer_ref.current.addEventListener('keydown',trapFocusKeydownListener);
      }
      else
      {
        if(originalActiveElement)
        {
          originalActiveElement.focus();
        }
        drawer_ref.current.removeEventListener('keydown',trapFocusKeydownListener);
      }
      const cleanupListener = () => {
        drawer_ref.current.removeEventListener('keydown',trapFocusKeydownListener);
      }
      return cleanupListener;      
    },
    [isOpen]
  );

  const drawer_class_name = classNames({
    "offcanvas-collapse": true,
    "is-right": true,
    open: isOpen,
  });

  return createPortal(
    <>
      {isOpen && (
        <div className="offcanvas-backdrop" onClick={dismissDrawer}></div>
      )}
      <div className={drawer_class_name} tabIndex="-1" ref={drawer_ref}>
        <div className="offcanvas-collapse-inner">{children}</div>
      </div>
    </>,
    document.body
  );
}
