import { ResponsiveProductImage } from "./ResponsiveProductImage";
import { useSelector } from "react-redux";
import {
  selectOwnerCode,
  selectResizeURL
} from "../store/orderingSlice";

export function BulkOrderingMainImage({ styleItem, className = "thumbnail"  }) {
  const owner_code = useSelector(selectOwnerCode);
  const resize_url = useSelector(selectResizeURL);
  const size_breakpoints = {
    0: 288
  };
  const srcset_widths = [
    288,
    576
  ];
  const default_width = 288;

  return (
    <ResponsiveProductImage
      resizeURL={resize_url}
      path={owner_code + "-" + styleItem.style_item_code + "-1.jpg"}
      srcsetWidths={srcset_widths}
      sizeBreakpoints={size_breakpoints}
      defaultWidth={default_width}
      alt={styleItem.style_item_name}
      className={className} />
  );
}


