import { useSelector, useDispatch } from "react-redux";
import { createPortal } from "react-dom";
import {
  selectBulkFirstStyleItemsFiltered,
  selectBulkSortBy,
  setBulkSortBy,
  selectBulkFilterText,
  setBulkFilterText,
  selectColorCount,
  selectSortableByPrice
} from "../store/orderingSlice";
import { BulkOrderingListCard } from "./BulkOrderingListCard";
import { SortSelect } from "./SortSelect";
import { FilterInput } from "./FilterInput";


export function BulkOrderingTable()
{
  const dispatch = useDispatch();
  const style_items = useSelector(selectBulkFirstStyleItemsFiltered);
  const color_count = useSelector(selectColorCount);

  const sort_by = useSelector(selectBulkSortBy);
  const filter_text = useSelector(selectBulkFilterText);
  const sortable_by_price = useSelector(selectSortableByPrice);


  return(
    <>
      <h2>Browse All {color_count} {color_count > 1 ? 'Colors':'Color'}</h2>
      <div className="color-browser__search-bar row">
        <div className="color-browser__sort-column col-6 col-xs-8">
          <FilterInput 
            filterText={filter_text}
            onChange={e => { dispatch(setBulkFilterText(e.target.value)) }}
          />
        </div>
        <div className="color-browser__sort-column col-6 col-xs-4">
          <SortSelect
            sortBy={sort_by}
            onChange={e => { dispatch(setBulkSortBy(e.target.value)) }}
            sortableByPrice={sortable_by_price}
          />
        </div>
      </div>
      <div className="color-browser container-fluid">
        <div className="row">
          {style_items.map(si => 
            <BulkOrderingListCard 
              key={si.style_item_ref}
              styleItem={si}
            />
          )}
        </div>
      </div>
    </>
  );

}

export function BulkOrdering({container})
{
  return createPortal(
    <BulkOrderingTable />,
    container
  )
}