export function ResponsiveProductImage({
  resizeURL,
  path,
  srcsetWidths,
  sizeBreakpoints,
  defaultWidth,
  alt = "",
  className = "",
  rotate = false,
}) {
  const sizes_array = [];
  for (const [breakpoint, width] of Object.entries(sizeBreakpoints)) {
    sizes_array.push(
      (breakpoint > 0 ? `(min-width:${breakpoint}px) ` : "") + `${width}px`
    );
  }

  const sizes_attribute = sizes_array.join(", ");
  const srcset_array = srcsetWidths.map(
    (width) => `${resizeURL}?path=${path}&nw=${width} ${width}w`
  );
  const srcset_attribute = srcset_array.join(", ");
  const src_attribute = `${resizeURL}?path=${path}&nw=${defaultWidth}`;

  return (
    <img
      className={className}
      src={src_attribute}
      srcSet={srcset_attribute}
      sizes={sizes_attribute}
      alt={alt}
    />
  );
}
