import React from "react";
import { createRoot } from "react-dom/client";
import OrderingWrapper from "./components/OrderingWrapper";

window.initializeOrderingApp = (container) => {
  const product_json = container.getAttribute("data-product-json");
  const bulk_ordering_container_id = container.getAttribute('data-bulk-ordering-id');
  const bulkOrderingDOMNode = document.getElementById(bulk_ordering_container_id);
  
  const stock_message_container_id = container.getAttribute('data-stock-message-id');
  const stockMessageDOMNode = document.getElementById(stock_message_container_id);

  const show_stock_message = container.getAttribute("data-show-stock-message") !== "false";  

  const root = createRoot(container);
  root.render(
    <OrderingWrapper
      appContainer={container}
      productJSON={product_json}
      bulkOrderingContainer={bulkOrderingDOMNode}
      showStockMessage={show_stock_message}
      stockMessageContainer={stockMessageDOMNode}
      bulkOrderingContainerId={bulk_ordering_container_id}
    />
  );
};
