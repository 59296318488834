import { useDispatch } from "react-redux";
import { useState } from "react";
import { SelectedStockMessage } from "./SelectedStockMessage";
import {
  bulkOrderAddToCart,
  dismissSpecsOrdering,
} from "../store/orderingSlice";
import classNames from "classnames";
import { isProductOrderable } from "../utilities/product_utilities";

export function SpecsOrdering({ product }) {
  const dispatch = useDispatch();
  const is_orderable = isProductOrderable(product);
  const [quantity, setQuantity] = useState(1);
  const [showClickError, setShowClickError] = useState(false);
  const effective_quantity = quantity === "" ? 1 : quantity;
  const effective_increment_quantity = quantity === "" ? 0 : quantity;
  const order_total = is_orderable ? product.price * effective_quantity : 0;

  const button_text =
    "Add to Cart" + (order_total > 0 ? " - $" + order_total.toFixed(2) : "");

  const button_class_name = classNames({
    "style_ordering-add_cart": true,
    btn: true,
    "button-cart": true,
    "btn-block": true,
    disabled: !is_orderable,
  });

  const updateQuantity = (value) => {
    const parsed = parseInt(value);
    setQuantity(isNaN(parsed) ? "" : parsed);
  };

  const decrementQuantity = () => {
    setQuantity(Math.max(effective_quantity - 1, 1));
  };

  const incrementQuantity = () => {
    setQuantity(effective_increment_quantity + 1);
  };

  const addToCart = () => {
    if (is_orderable) {
      const ordered_event_data = [
        {
          code: product.code,
          quantity: effective_quantity,
        },
      ];
      dispatch(bulkOrderAddToCart(ordered_event_data));
      dispatch(dismissSpecsOrdering());
    } else {
      setShowClickError(true);
    }
  };

  return (
    <>
      <SelectedStockMessage matches={[product]} />
      {showClickError && (
        <div class="alert alert-danger is-invalid">
          <span class="icon icon-warning"></span>The selected item is unavailable for purchase at this time.
        </div>
      )}
      <div className="ordering_area">
        <div className="style_ordering-inventory row">
          <div className="col-12">
            <div className="qty_section enhanced">
              <label
                className="section_label"
                htmlFor="specs-panel__quantity-input"
              >
                Quantity:
                <span className="qty_entered">{effective_quantity}</span>
              </label>
              <div className="qty_button_wrap input-group cf">
                <div className="input-group-prepend">
                  <button
                    type="button"
                    className="decrement btn"
                    onClick={(e) => {
                      decrementQuantity();
                    }}
                  >
                    <span className="icon icon-minus">Decrement</span>
                  </button>
                </div>
                <input
                  id="specs-panel__quantity-input"
                  className="ordering_qty style_ordering-qty form-control"
                  type="text"
                  name={product.code}
                  value={quantity}
                  autoComplete="off"
                  onChange={(e) => {
                    updateQuantity(e.target.value);
                  }}
                />
                <div className="input-group-append">
                  <button
                    type="button"
                    className="increment btn"
                    onClick={(e) => {
                      incrementQuantity();
                    }}
                  >
                    <span className="icon icon-plus">Increment</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="ordersubmit_wrap" className="style_ordering-submits">
          <div className="style_ordering-submits-item is-add_to_cart is-full">
            <button
              className={button_class_name}
              type="button"
              onClick={(e) => {
                addToCart();
              }}
            >
              {button_text}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
